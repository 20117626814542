@import url("https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Neue Haas Grotesk Display Pro", "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body {
  overflow: hidden;
  line-height: 1.2;
  flex: 1;
}

.header {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 12.5rem;
}

